import * as React from "react"
import classnames from 'classnames'
import CardSA, { CardSAParams } from '../CardSA'
import { 
  ComponentContainer
} from './index.module.less'

interface Params {
  className: string
  list: CardSAParams[]
}

const CardSAGroup = (params: Params) => {
  return (
    <div className={classnames(ComponentContainer, params.className)}>
      {
        params?.list?.map((item, index) => <CardSA 
          key={index}
          className={classnames('card-item', item.className)}
          icon={item.icon}
          iconAlt={item.iconAlt}
          content={item.content}
        />)
      }
    </div>
  );
}

export default CardSAGroup;