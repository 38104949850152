import * as React from "react"
import classnames from 'classnames'
import { 
  ComponentContainer
} from './index.module.less'

interface Params {
  className: string
  title: string
  buttonList: []
}

const SAFooter = (params: Params) => {
  return (
    <section className={classnames(ComponentContainer, params.className)}>
      <article>
        <h1>{params.title}</h1>
        {params.buttonList && <div className="sd-button-group">
          {
            params?.buttonList?.map(item => item)
          }
        </div>}
        <div className="copy">
          <p>神策网络科技（北京）有限公司</p>
          <p>联系我们： mkt@sensorsdata.cn</p>
          <p>电话：400-650-9827</p>
        </div>
      </article>
    </section>
  );
}

export default SAFooter;