import * as React from "react"
import Seo from "components/seo"
import Layout from "layouts/zh"
import Button from 'components/Base/Button'
import SectionContent from 'components/SectionContent'
import BannerSA23 from 'components/BannerSA2_3'
import MediaListSA from 'components/MediaListSA'
import HeaderOnlyLogo from 'components/HeaderOnlyLogo'
import CardSAGroup from 'components/CardSAGroup'
import SAFooter from 'components/SAFooter'
import Letter from 'components/Letter'
import {
  PageContainer
} from './index.module.less'
import IconBanner from 'assets/images/product/sa_2_3/icon-banner.png'
import Icon01 from 'assets/images/product/sa_2_3/icon-01.png'
import Icon02 from 'assets/images/product/sa_2_3/icon-02.png'
import Icon03 from 'assets/images/product/sa_2_3/icon-03.png'
import Icon04 from 'assets/images/product/sa_2_3/icon-04.png'
import Thumb01 from 'assets/images/product/sa_2_3/thumb-01.png'
import Thumb02 from 'assets/images/product/sa_2_3/thumb-02.png'
import Thumb03 from 'assets/images/product/sa_2_3/thumb-03.png'

const SA2_3 = () => {

  return (
    <Layout 
      headerType="black"
      header={<HeaderOnlyLogo />}
      hideFooter={true}
      >
      <Seo 
        title="神策分析 2.3 重磅来袭"
        description=""
        keywords=""
        saTitle="" />
      <main className={PageContainer}>
        <BannerSA23
          className="banner-wrap oval"
          tips="分析云之"
          title={<>神策分析 2.3 重磅来袭</>}
          desc="立即升级至神策分析 2.3 版本，体验前所未有的感知与决策能力飞跃！"
          buttonList={[
            <Button 
              btnType="gradients" 
              round
              href="/doMatrixSa"
            >体验全新 Demo<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-white.svg" alt="" /></Button>,
            <Button 
            btnType="gradients" 
              round 
              plain
              href="https://www.sensorsdata.cn/service/onlinemore.html?section=41&type=%E7%A5%9E%E7%AD%96%E5%88%86%E6%9E%90%202.3"
            >观看培训视频<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-black.svg" alt="" /></Button>
          ]}
          poster={IconBanner}
          videoSource={[
            {
              src: "https://video.sensorsdata.cn/f971807352374183a916885721d3cbe3/44ecf5039ac745459124857c7a80fdc5-d6170fecf7591ccc910ec687523907c3-sd.mp4",
              type: "video/mp4"
            }
          ]}
        ></BannerSA23>
        <Letter className="letter-wrap oval" 
          named="尊敬的用户："
          line1={<>感谢您对神策分析云产品的信任。</>}
          line2={<><span className="highlight">神策分析 2.3 版本现已正式推出，带来三大全新能力：智能预警分析、场景商店、全新导航栏。</span>在分析能力升级与用户体验优化的同时，也将始终如一地为您带来更多价值。</>}
          line3={<>您可以通过体验全新产品 Demo、观看培训视频、阅读产品文档及联系您的客户成功团队，以了解更多内容。</>}
          line4={<span className="highlight">我们诚邀您体验神策分析 2.3 的完整价值，也将以完善的服务体系支持您的升级。</span>}
        />
        <SectionContent className="new-power" title="三大全新能力，让感知更智能，决策更可靠，行动更高效！">
          <MediaListSA className=""
            list={[
              {
                className: 'np-01',
                title: '智能预警分析',
                desc: '为您的业务提供可靠的风险管理',
                list: [
                  '以先进的 Prophet 算法，智能预测业务指标在一定时间内的变化区间，提升科学性与便捷性，同时避免人工计算误差与经验主义的影响',
                  '支持 Webhook 能力，可通过平台内、办公通讯工具、邮件等多渠道推送告警通知',
                  '基于自动化的指标相关性分析，定位异常数据维度与用户，迅速发现指标关联与潜在风险，助您提升问题排查的效率'
                ],
                buttonList: [
                  <Button 
                  btnType="gradients" 
                    round
                    href="/doMatrixSa"
                  >体验全新 Demo<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-white.svg" alt="" style={{marginLeft: 10}}/></Button>,
                  <Button 
                  btnType="gradients" 
                    round
                    plain
                    href="https://manual.sensorsdata.cn/sa/latest/guide_warning-33915690.html"
                  >阅读产品文档<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-black.svg" alt="" style={{marginLeft: 10}}/></Button>
                ],
                img: Thumb01,
                imgAlt: 'thumb',
                imgWidth: 617
              },
              {
                className: 'np-02',
                title: '场景商店',
                desc: '内嵌多行业场景库，一键配置行业高质量概览',
                list: [
                  '场景商店内的概览模板来自 1500+ 客户与 30+ 行业的服务经验沉淀，包括通用场景与行业化场景',
                  '场景模板具备概览的全部能力，同时支持虚拟事件与虚拟属性的呈现',
                  '支持通过模板配置概览后的二次编辑，可与概览双向转换，更为灵活易用'
                ],
                buttonList: [
                  <Button 
                  btnType="gradients" 
                    round
                    href="/doMatrixSa"
                  >体验全新 Demo<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-white.svg" alt="" style={{marginLeft: 10}}/></Button>,
                  <Button 
                  btnType="gradients" 
                    round
                    plain
                    href="https://manual.sensorsdata.cn/sa/latest/guide_scene-33915766.html"
                  >阅读产品文档<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-black.svg" alt="" style={{marginLeft: 10}}/></Button>
                ],
                img: Thumb02,
                imgAlt: 'thumb',
                imgWidth: 686
              },
              {
                className: 'np-03',
                title: '全新导航栏',
                desc: '跨产品线自由组合功能组件，更流畅的产品操作体验',
                list: [
                  '从用户习惯出发，在同一导航栏内实现不同产品线功能组件的融合，让涉及完整业务流程的所有组件调度更高效',
                  '您也可以保留产品线之间的切换功能，通过统一的产品线切换入口或浏览记录快速实现产品切换',
                  '导航栏变为双层设计，直观区分管理入口与功能入口，并支持菜单层级、图文描述等功能的自定义配置'
                ],
                buttonList: [
                  <Button 
                  btnType="gradients" 
                    round
                    href="/doMatrixSa"
                  >体验全新 Demo<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-white.svg" alt="" style={{marginLeft: 10}}/></Button>,
                  <Button 
                  btnType="gradients" 
                    round
                    plain
                    href="https://manual.sensorsdata.cn/sa/latest/page-33915981.html"
                  >阅读产品文档<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-black.svg" alt="" style={{marginLeft: 10}}/></Button>
                ],
                img: Thumb03,
                imgAlt: 'thumb',
                imgWidth: 671
              }
            ]} 
          />
        </SectionContent>
        <SectionContent className="service-wrap oval top" title="以完善的服务体系保障您的升级">
          <p className="desc">升级请联系您的客户成功团队，先人一步获取更强大的数据驱动能力！</p>
          <CardSAGroup className="cardsa-group"
            list={[
              {
                className: 'f-01',
                icon: Icon01,
                iconAlt: '客户成功团队一对一支持，分批次升级保障服务质量',
                content: '客户成功团队一对一支持，分批次升级保障服务质量'
              },
              {
                className: 'f-02',
                icon: Icon02,
                iconAlt: '为采用不同产品组合的用户出具定制化的升级方案',
                content: '为采用不同产品组合的用户出具定制化的升级方案'
              },
              {
                className: 'f-03',
                icon: Icon03,
                iconAlt: '全程对接神策产研团队，提供专业可靠的技术支持',
                content: '全程对接神策产研团队，提供专业可靠的技术支持'
              },
              {
                className: 'f-04',
                icon: Icon04,
                iconAlt: '提供完整的新产品培训视频与各功能模块操作详解',
                content: '提供完整的新产品培训视频与各功能模块操作详解'
              }
            ]}
          />
          <div className="sd-button-group">
            <Button 
              btnType="gradients" 
              round
              href="/doMatrixSa"
            >体验全新 Demo<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-white.svg" alt="" style={{marginLeft: 10}}/></Button>
          </div>
        </SectionContent>
      </main>
      <SAFooter />
    </Layout>
  );
}

export default SA2_3;