import * as React from "react"
import classnames from 'classnames'
import Video, { VideoSource } from '../Base/Video'
import { 
  Container
} from './index.module.less'

interface Params {
  className: string;
  tips: string;
  title: any;
  desc: string;
  buttonList: any[];
  icon?: any;
  poster?: string;
  videoSource?: VideoSource[]
}

const Conponent = (params: Params) => {
  const { icon, poster, videoSource } = params;
  return (
    <section className={classnames(Container, params.className)}>
      <article>
        <div className="banner-sa2_3-left">
          <span className="tips">{params.tips}</span>
          <h1>{params.title}</h1>
          <p>{params.desc}</p>
          <div className="sd-button-group">
            {params?.buttonList?.map(item => item)}
          </div>
        </div>
        <div className="banner-sa2_3-right">
          {icon && <img className="icon-banner" src={icon} alt="" />}
          {videoSource && <Video className="icon-banner" poster={poster} source={videoSource} />}
        </div>
      </article>
    </section>
  );
}

export default Conponent;