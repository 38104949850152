import * as React from "react"
import classnames from 'classnames'
import { 
  ComponentContainer
} from './index.module.less'

interface Item {
  className: string
  title: string
  desc: string
  list: string[]
  buttonList: any[]
  img: string
  imgAlt: string
  imgClassName: string
  imgWidth: number
}

interface Params {
  className: string
  list: Item[]
}

const MediaListSA = (params: Params) => {
  return (
    <div className={ComponentContainer}>
      {
        params.list?.map((item, index) => <div className={classnames('media-item',item.className)} key={index}>
          <div className="content">
            <h3>{item.title}</h3>
            <p>{item.desc}</p>
            <ul>
              {
                item.list?.map(ditem => <li>{ditem}</li>)
              }
            </ul>
            <div className="sd-button-group hidden-mb">
              {
                item.buttonList?.map(buttonItem => buttonItem)
              }
            </div>
          </div>
          <div className="thumb-wrap">
            <img className={item.imgClassName} src={item.img} alt={item.imgAlt} style={{width: item.imgWidth}}/>
            <div className="sd-button-group hidden-pc">
              {
                item.buttonList?.map(buttonItem => buttonItem)
              }
            </div>
          </div>
        </div>)
      }
    </div>
  );
}

export default MediaListSA;