import * as React from "react";
import classnames from 'classnames';
import { Container } from "./index.module.less"

interface Params {
  className: string
  named: string
  line1: React.Component
  line2: React.Component
  line3: React.Component
  line4: React.Component
}

const Conponent = (params: Params) => {
  return (
    <section className={classnames(Container, params.className)}>
      <article>
        <p className="named">{params.named}</p>
        <div className="content">
          <p className="line line1">{params.line1}</p>
          <p className="line line2">{params.line2}</p>
          <p className="line line3">{params.line3}</p>
          <p className="line line4">{params.line4}</p>
        </div>
      </article>
    </section>
  );
}

export default Conponent;