/* eslint-disable */
import * as React from "react";
import classnames from 'classnames';
import * as styles from "./index.module.less"

import LogoBlack from 'assets/images/logo/logo-black.png';

const SDHeader = ({
  className,
  style
}) => {

  return (
    <header
      className={classnames(styles.headerContainer, className)}
      style={style}>
      <nav className="nav-container">
        <a className="logo" name="area_header" href="/">
          <img
            className="logo-white"
            src={LogoBlack}
            alt="logo"
          />
        </a>
      </nav>
    </header>
  );
};

export default SDHeader;
/* eslint-disable no-new */