import * as React from "react"
import classnames from 'classnames'
import { 
  ComponentContainer
} from './index.module.less'

export interface CardSAParams {
  className: string
  icon: string
  iconAlt: string
  content: string
}

const CardSA = (params: CardSAParams) => {
  return (
    <div className={classnames(ComponentContainer, params.className)}>
      <div className="icon-wrap">
        <img src={params.icon} alt={params.iconAlt} />
      </div>
      <div className="content">{params.content}</div>
    </div>
  );
}

export default CardSA;